module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  fi: {
    path: 'fi',
    locale: 'Suomi',
  },
}
