import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import Sticky from 'react-sticky-el'
import Navbar from './Navbar'
import FloatingHomeImage from '../assets/img/et_bolder.svg'
import LocalizedLink from './LocalizedLink'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showNav: false,
      showLanguageSwitcher: false,
      languageSwitcherClassName: '',
      lang: this.props.lang,
      translatedPages: this.props.translations,
      allPages: null,
    }
    this.hideNav = this.hideNav.bind(this)
  }

  toggleClass() {
    const { showNav } = this.state
    const currentState = showNav
    this.setState({ showNav: !currentState })

    if (!currentState) document.body.classList.add('nav-open')
    if (currentState) document.body.classList.remove('nav-open')
  }

  hideNav() {
    this.setState({ showNav: false })
    document.body.classList.remove('nav-open')
  }

  toggleLanguageSwitcher() {
    this.setState({ showLanguageSwitcher: !this.state.showLanguageSwitcher })

    if (!this.state.showLanguageSwitcher) {
      this.setState({ languageSwitcherClassName: 'open' })
    } else {
      this.setState({ languageSwitcherClassName: '' })
    }
  }

  showLanguageSwitcher() {
    this.setState({ showLanguageSwitcher: true })
    this.setState({ languageSwitcherClassName: 'open' })
  }

  hideLanguageSwitcher() {
    this.setState({ showLanguageSwitcher: false })
    this.setState({ languageSwitcherClassName: '' })
  }

  // language switch controller
  languageController = e => {
    const selectedLanguage = e.target.getAttribute('data-language-type')
    const currentLanguage = this.props.lang
    const translationID = this.props.translations[selectedLanguage]

    const translatedLanguageID = translationID !== null ? translationID : false
    const selectedCurrentLanguage =
      selectedLanguage === currentLanguage ? true : false

    // redirect options
    if (translatedLanguageID && !selectedCurrentLanguage)
      this.filterRedirection(translatedLanguageID, selectedLanguage)
    if (!translatedLanguageID && !selectedCurrentLanguage)
      this.redirectPage(`/${selectedLanguage}/`)
    if (!translatedLanguageID && selectedCurrentLanguage) this.redirectPage('/')
  }

  // search for the redirected path
  filterRedirection = (translationID, selectedLanguage) => {
    const {
      allWordpressPost,
      allWordpressPage,
      allWordpressWpReference,
    } = this.props.data

    this.filterLanguageMatch(allWordpressPost, translationID, selectedLanguage)
    this.filterLanguageMatch(allWordpressPage, translationID, selectedLanguage)
    this.filterLanguageMatch(
      allWordpressWpReference,
      translationID,
      selectedLanguage
    )
  }

  // check if the corrolated translation is a page
  filterLanguageMatch = (page, translationID, selectedLanguage) => {
    page.edges.map(pageRedirect => {
      if (typeof pageRedirect !== undefined) {
        const langPage = pageRedirect.node

        // check if the language id match and if the page has the same language tag as the selected language
        if (
          langPage.translations[selectedLanguage] === translationID &&
          langPage.lang === selectedLanguage
        ) {
          this.updateLanguageState(selectedLanguage)
          return this.redirectPage(langPage.path)
        }
      }
    })
  }

  updateLanguageState = selectedLanguage => {
    this.setState({ lang: selectedLanguage })
  }

  // if there is no translated page, redirection to home page with selected language
  redirectPage = path => navigate(`${path}`)

  render() {
    const { lang } = this.state
    const menuList = this.props.data.allWordpressWpApiMenusMenusItems.edges

    const languages = ['en', 'fi']

    const languageSwitcher = languages.map(language => {
      const prefix = language !== 'en' ? `/${language}` : '/'

      let activeClass = ''

      if (language === lang) {
        activeClass = 'active'
      }

      return (
        <li className={activeClass}>
          <Link
            to={prefix}
            onClick={
              (this.hideLanguageSwitcher.bind(this), this.hideNav.bind(this))
            }
          >
            {language}
          </Link>
        </li>
      )
    })

    return (
      <>
        <header className="site-header">
          <LocalizedLink onClick={this.hideNav} className="logo" to="/">
            Don & Branco
          </LocalizedLink>
          <button
            type="button"
            className="togglenav"
            onClick={this.toggleClass.bind(this)}
          >
            <span className="hamburger" />
          </button>
          <ul
            className={`language-switcher ${this.state.languageSwitcherClassName}`}
            onMouseEnter={this.showLanguageSwitcher.bind(this)}
            onMouseLeave={this.hideLanguageSwitcher.bind(this)}
            onClick={this.toggleLanguageSwitcher.bind(this)}
          >
            {languageSwitcher}
          </ul>
          <Navbar
            hideNavFunction={this.hideNav}
            languageController={this.languageController}
            menuList={menuList}
            lang={lang}
            languageSwitcher={languageSwitcher}
          />
        </header>
        <div className="floating-home-wrapper">
          <Sticky
            boundaryElement=".floating-home-wrapper"
            hideOnBoundaryHit={false}
            style={{ position: 'relative' }}
          >
            <Link onClick={this.hideNav} className="floating-home" to="/">
              <img src={FloatingHomeImage} alt="Home" />
            </Link>
          </Sticky>
        </div>
      </>
    )
  }
}

export default Header
