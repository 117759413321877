import React from 'react'
import Helmet from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Link, graphql, useStaticQuery, StaticQuery } from 'gatsby'
import { IntlProvider } from 'react-intl'

import Header from './Header'

// Messages
import en from '../i18n/en.json'
import fi from '../i18n/fi.json'

const messages = { en, fi }

const TemplateWrapper = ({ children, lang, translations }) => {
  // get all pages with their unique translated ID's for language switcher
  const data = useStaticQuery(graphql`
    query AllWordpressPost {
      allWordpressPost {
        edges {
          node {
            slug
            id
            lang
            path
            translations {
              en
              fi
            }
          }
        }
      }
      allWordpressWpReference {
        edges {
          node {
            slug
            id
            lang
            path
            translations {
              en
              fi
            }
          }
        }
      }
      allWordpressPage {
        edges {
          node {
            slug
            id
            lang
            path
            translations {
              en
              fi
            }
          }
        }
      }
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            slug
            items {
              wordpress_id
              order
              wordpress_parent
              title
              url
              attr
              target
              classes
              xfn
              description
              object_id
              object
              object_slug
              type
              type_label
            }
          }
        }
      }
    }
  `)

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <ParallaxProvider>
        <div className="master">
          <Helmet title="Don & Branco" />
          <Header lang={lang} translations={translations} data={data} />
          <main>{children}</main>
        </div>
      </ParallaxProvider>
    </IntlProvider>
  )
}

export default TemplateWrapper
