import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

const Navbar = ({
  hideNavFunction,
  languageController,
  menuList,
  lang,
  languageSwitcher,
}) => {
  const menu = menuList.map(menus => {
    const { slug, items } = menus.node

    if (slug.includes(lang)) {
      return items.map((item, i) => {
        const prefix = lang !== 'en' ? `/${lang}` : ''

        switch (item.type) {
          case 'post_type':
            return (
              <li key={`${i}-post`}>
                <Link
                  to={`${prefix}/${item.object_slug}`}
                  key={item.wordpress_id}
                  activeClassName="active"
                  onClick={hideNavFunction}
                >
                  {item.title}
                </Link>
              </li>
            )

          default:
            return (
              <li key={`${i}-default`}>
                <a key={item.wordpress_id} href={item.url}>
                  {item.title}
                </a>
              </li>
            )
        }
      })
    }
  })

  return (
    <nav>
      <div className="some">
        <a
          href="https://www.linkedin.com/company/don-&-branco"
          className="icon-linkedin"
          target="_blank"
        >
          <span>LinkedIn</span>
        </a>
        <a
          href="https://www.instagram.com/don_branco/"
          className="icon-instagram"
          target="_blank"
        >
          <span>Instagram</span>
        </a>
        <a
          href="https://www.facebook.com/donbranco.fi/"
          className="icon-facebook"
          target="_blank"
        >
          <span>Facebook</span>
        </a>
      </div>
      <ul>
        {menu}
        {/* <li onClick={languageController} data-language-type="fi">
          fi
        </li>
        <li onClick={languageController} data-language-type="en">
          en
        </li> */}
      </ul>
      <ul className="language-switcher-mobile">{languageSwitcher}</ul>
    </nav>
  )
}

export default Navbar
